export enum GA_PAGE_TYPE {
  AUTOS_LANDING_PAGE = 'landing_page',
  BROWSE_AD = 'ResultsBrowse',
  CAR_VALUE_CALCULATOR = 'CarValueCalculator',
  ERROR_404 = 'Error_404',
  ERROR_500 = 'Error_500',
  FORGOT_PASSWORD = 'PasswordForgotten',
  HOMEPAGE = 'Homepage',
  MOBILE_APP_LINK = 'MobileAppLink',
  PASSWORD_RESET_FORM = 'PasswordResetForm',
  SEARCH_AD = 'ResultsSearch',
  SEARCH_RESULTS_PAGE = 'SRP',
  MAP_SEARCH_RESULTS_PAGE = 'ResultsSearchMap',
  SELL_MY_CAR = 'SellMyCar',
  SIGNIN_ERROR = 'SigninErrorPage',
  USER_REGISTRATION = 'UserRegistrationForm',
  USER_REGISTRATION_PAGE = 'UserRegistrationLandingPage',
  VIEW_AD = 'VIP',
  VIEW_EXPIRED_AD = 'eVIP',
  ZERO_SEARCH_RESULTS_PAGE = 'ZSRP',
}

export enum DATALAYER_SRP_VIEW_TYPE {
  GALLERY = 'GAL',
  LIST = 'LST',
  MAP = 'MAP',
}

/**
 * The below object dictates the badge names and its GA equivalent values
 * */
export const DATALAYER_SRP_FLAG_MAP: Record<string, string> = {
  featured: '001',
  reduced: '002',
  urgent: '003',
  virtualTour: '004',
  highlight: '005',
  showcase: '006',
  priceDrop: '007',
}

export const GPT_PAGE_TYPE: { [key: string]: string } = {
  Homepage: 'homepage',
  landing_page: 'landing_page',
  Error_404: '404page',
  Error_500: 'errorpage',
  VIP: 'viewitem',
  ResultsSearch: 'srp',
  ResultsSearchMap: 'srp_map',
  ResultsBrowse: 'srp',
  ViewLargerImage: 'viewimage',
  eVIP: 'evip',
  PostAdSuccess: 'postad',
  ViewMap: 'map',
  ZSRP: 'zsrp',
  MyAds: 'mykijiji',
  ResultsSeller: 'poa',
  'home-sweet-home': 'home-sweet-home',
  'back-to-school': 'back-to-school',
  'landing-page-general': 'landing-page-general',
  VIEW_A_CAS: 'viewitem-cas', // outlier
}
