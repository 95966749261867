import Image from 'next/image'
import { useTheme } from 'styled-components'

import { ErrorContainer, ErrorImageContainer } from '@/components/shared/error/styled'
import { BodyText } from '@/ui/atoms/body-text'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

type GenericErrorProps = {
  errorTitle: string
  errorBody: string
  children?: React.ReactNode
}

export const GenericError = ({ errorTitle, errorBody, children }: GenericErrorProps) => {
  const { spacing, colors } = useTheme()

  return (
    <ErrorContainer>
      <ErrorImageContainer>
        <Image alt="" fill src="/next-assets/images/deleteAccount.svg" />
      </ErrorImageContainer>
      <Spacing mBottom={spacing.default} mTop={spacing.large}>
        <HeadlineText color={colors.grey.primary}>{errorTitle}</HeadlineText>
      </Spacing>
      <BodyText color={colors.grey.light1} textAlign="center">
        {errorBody}
      </BodyText>
      {children}
    </ErrorContainer>
  )
}
