import { type Session } from 'next-auth'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { type DataLayerBase } from '@/lib/ga/types/dataLayer'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { getHashedUserIdFromCookie } from '@/utils/cookies/getHashedUserIdFromCookie'

/**
 * Returns the user object of the the datalayer
 *
 * @param session - User Session
 * @returns
 */
export const getDatalayerUser = async (
  session?: Session | null,
  experiments: string = ''
): Promise<Pick<DataLayerBase, 'u'>> => {
  const isLoggedIn = !!session?.user
  const accountType = session?.user?.type

  /**
   * To protect against trackingKeys flip-flopping when a user hits different
   * versions of NWA, we prefer to take the experiments key from the cookie,
   * but if it is somehow not present, we can fall back to the client side
   * evaluated experiments
   *
   * We could theoretically save some compute cycles on the client side by
   * extricating the experiment evaluation that provides the experiments arg
   * to this hook, but in the interest of getting this finished, I'm not doing
   * that right now.
   * */
  const stg = getCookieByKey(document.cookie, CookieRegistry.SESSION_TEST_GROUP)
    ? decodeURIComponent(getCookieByKey(document.cookie, CookieRegistry.SESSION_TEST_GROUP))
    : experiments

  return {
    u: {
      at: accountType,
      huid: isLoggedIn ? getHashedUserIdFromCookie() : undefined,
      li: isLoggedIn,
      tg: { stg },
    },
  }
}
