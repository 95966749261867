import { GetUserLocaleDocument, useGetUserLocaleQuery } from '@kijiji/generated/graphql-types'
import { type GetStaticProps, type NextPage } from 'next'
import { NextSeo } from 'next-seo'
import { useEffect } from 'react'

import { ClientRender } from '@/components/shared/client-render'
import { GenericError } from '@/components/shared/error'
import { BaseLayout } from '@/components/shared/layouts/BaseLayout'
import { RouteLink } from '@/components/shared/route-link/RouteLink'
import { TRANSLATION_KEYS } from '@/constants/localization'
import { ROUTES } from '@/constants/routes'
import { getLocale } from '@/domain/locale'
import { addApolloState, initializeApollo } from '@/lib/apollo/apolloClient'
import { GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import { useTracking } from '@/lib/ga/hooks/useTracking'
import { TextLink } from '@/ui/atoms/text-link'
import { updateUserLocaleCache } from '@/utils/locale/updateUserLocale'
import { serverSideTranslations } from '@/utils/serverSideTranslations'

/**
 * Translations are still not working properly with this page
 * The base layout is not switching translations as it does with all other pages
 */

const NotFoundPage: NextPage = () => {
  useTracking({ pageType: GA_PAGE_TYPE.ERROR_404 })

  const { routeLocale } = getLocale()
  const { data, client } = useGetUserLocaleQuery()

  useEffect(() => {
    const { routeLocale } = getLocale()
    if (routeLocale !== data?.userLocale.routeLocale) {
      updateUserLocaleCache(routeLocale, client)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 404 is a static page that doesn't allow usage of getStaticPaths
   * Translations won't work like in the other pages
   * We can revisit this implementation once we migrate to route pages
   */
  const translations = {
    en: {
      'seo.meta.title': 'Page not found',
      title: 'Hmm... Apparently this page no longer exists.',
      body: 'Most likely the page has been removed or there was a typo in the link.',
      link: 'Go to Homepage',
    },
    fr: {
      'seo.meta.title': 'Page non trouvée',
      title: "Bon, il semblerait que la page n'existe plus.",
      body: 'La page a sûrement été retirée ou le lien URL a une petite faute.',
      link: "Retour à la page d'accueil",
    },
  }

  const translation = translations[routeLocale]

  return (
    <BaseLayout>
      <NextSeo title={translation['seo.meta.title']} />

      <ClientRender>
        <GenericError errorTitle={translation.title} errorBody={translation.body}>
          <RouteLink route={ROUTES.HOMEPAGE}>
            <TextLink variant="secondary">{translation.link}</TextLink>
          </RouteLink>
        </GenericError>
      </ClientRender>
    </BaseLayout>
  )
}

export default NotFoundPage

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const userLocale = getLocale(locale)

  const apolloClient = initializeApollo()
  apolloClient.writeQuery({ query: GetUserLocaleDocument, data: { userLocale } })

  const translations = await serverSideTranslations(userLocale.routeLocale, [
    TRANSLATION_KEYS.NOT_FOUND,
  ])
  const props = addApolloState(apolloClient, { ...translations })

  return { props }
}
