import { type Session } from 'next-auth'

import { type GA_PAGE_TYPE } from '@/lib/ga/constants/datalayer'
import {
  type DataLayerBase,
  type DataLayerCategory,
  type DataLayerLocation,
} from '@/lib/ga/types/dataLayer'
import { getDatalayerCategory } from '@/lib/ga/utils/getDatalayerCategory'
import { getDatalayerDevice } from '@/lib/ga/utils/getDatalayerDevice'
import { getDatalayerLocation } from '@/lib/ga/utils/getDatalayerLocation'
import { getDatalayerPage } from '@/lib/ga/utils/getDatalayerPage'
import { getDatalayerUser } from '@/lib/ga/utils/getDatalayerUser'

export type GetBaseDatalayerProps = {
  categoryId?: number
  locationId: number
  pageType: GA_PAGE_TYPE
  session?: Session | null
  /** Firebase experiements string */
  experiments?: string
}

/**
 * Returns the category object of the the datalayer
 */
export const getBaseDatalayer = async ({
  categoryId,
  locationId,
  pageType,
  session,
  experiments,
}: GetBaseDatalayerProps): Promise<DataLayerBase & DataLayerCategory & DataLayerLocation> => {
  const { d } = getDatalayerDevice()
  const { l } = getDatalayerLocation(locationId)
  const { p } = getDatalayerPage(pageType)
  const { u } = await getDatalayerUser(session, experiments)
  const { c } = await getDatalayerCategory({ categoryId, locationId })

  return { event: 'page_meta', d, p, u, l, c }
}
